<template>
  <div v-if="isDataLoaded">
    <v-tabs v-model="tab" color="secondary" class="high-tabs">
      <v-tabs-slider color="primary" />
      <v-tab v-for="{ id, name, to } in tabs" :key="id" class="text-capitalize" :to="to" v-text="name" />
    </v-tabs>
    <v-divider />
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'PushNotifications',
  props: {
    organizationId: { type: String, required: true },
    clusterId: { type: String, default: null },
    projectId: { type: String, default: null },
  },
  data() {
    return {
      isDataLoaded: false,
      tab: null,
    }
  },
  computed: {
    viewEntityType({ clusterId, projectId }) {
      if (projectId) return 'project'
      if (clusterId) return 'cluster'
      return 'organization'
    },
    tabs({ viewEntityType }) {
      return [
        {
          id: 'create',
          name: this.$t('pushNotifications.create'),
          to: { name: `${viewEntityType}-pushNotifications-create` },
        },
        {
          id: 'history',
          name: this.$t('pushNotifications.history'),
          to: { name: `${viewEntityType}-pushNotifications-history` },
        },
      ]
    },
  },
  async created() {
    this.$store.commit('loader/show')
    const { organizationId } = this.$route.params
    await this.$store.dispatch('pushNotifications/bindHistory', organizationId)
    this.$store.commit('loader/hide')
    this.isDataLoaded = true
  },
}
</script>

<style lang="scss" scoped>
.high-tabs {
  display: flex;
  align-items: flex-end;
  height: 70px;
}
</style>
